import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { AlertDialogService } from "./../../../components/_alert-dialog/alert-dialog.service";
import { HttpService } from "./../../../services/http.service";
import { ToolsService } from "./../../../services/tools.service";
import { Incident } from "./incident.class";

@Injectable()
export class IncidentsService implements OnDestroy {
  private oLogUser = JSON.parse(sessionStorage.getItem("Gesteam_User"));
  private _incidents: BehaviorSubject<Incident[]> = new BehaviorSubject([]);

  public incidents$: Observable<Incident[]> = this._incidents.asObservable();
  public load: boolean = false;

  public service_ID: number = 0;
  public installation_ID: number = 0;
  public dDebut: string = "";
  public dFin: string = "";
  public typeIncident: number = 0;

  constructor(
    private _http: HttpService,
    private _tools: ToolsService,
    private _alert: AlertDialogService
  ) {}

  ngOnDestroy(): void {
    this._incidents.unsubscribe();
  }

  async get(
    service_ID: number,
    installation_ID: number,
    dDebut: string,
    dFin: string,
    typeIncident: number
  ) {
    this.load = true;
    this.oLogUser = JSON.parse(sessionStorage.getItem("Gesteam_User"));

    this.service_ID = service_ID;
    this.installation_ID = installation_ID;
    this.dDebut = dDebut;
    this.dFin = dFin;
    this.typeIncident = typeIncident;

    let salarie_ID = this.oLogUser.login_ID;
    if (this.oLogUser.droits["VALIDATION_INCIDENT"] > 0) salarie_ID = 0;

    this._http.GET(
      "/api/Incident",
      {
        Salarie_ID: salarie_ID,
        Service_ID: service_ID,
        Installation_ID: installation_ID,
        dDebut: dDebut,
        dFin: dFin,
        Etat: typeIncident,
        bTemp: true,
        iLogin_ID: this.oLogUser.login_ID,
      },
      (data) => {
        console.log("incidents", data);
        if (data.isOk) {
          this._incidents.next(data.obj);
        }
        this.load = false;
      },
      (error) => {
        this._tools.showHttpError(error);
        this.load = false;
      }
    );
  }

  async post(inc: Incident, statut: number) {
    this.load = true;

    let webService = "";
    switch (statut) {
      case 1:
        webService = "ValidIncidentTemp";
        break;
      case 2:
        webService = "RefuseIncidentTemp";
        break;
      case 3:
        webService = "CancelIncidentTemp";
        break;
    }

    this._http.POST(
      "/api/Incident/" + webService,
      inc,
      (result) => {
        if (result.isOk) {
          this._alert.openDialog(
            "Félicitation !",
            { message: "L'incident a bien été enregistré" },
            "success"
          );
        } else {
          this._alert.openDialog(
            "Erreur",
            { message: result.message },
            "error"
          );
        }
        this.load = false;
        this.get(
          this.service_ID,
          this.installation_ID,
          this.dDebut,
          this.dFin,
          this.typeIncident
        );
      },
      (error) => {
        this._tools.showHttpError(error);
        this.load = false;
      }
    );
  }

  async delete(ID: number) {
    this.load = true;
    this._http.DELETE(
      "/api/Incident/" + ID + "/true",
      null,
      (result) => {
        if (result.isOk) {
          this._alert.openDialog(
            "Félicitation !",
            { message: "L'incident a bien été supprimé" },
            "success"
          );
        } else {
          this._alert.openDialog(
            "Erreur",
            { message: result.message },
            "error"
          );
        }
        this.load = false;
        this.get(
          this.service_ID,
          this.installation_ID,
          this.dDebut,
          this.dFin,
          this.typeIncident
        );
      },
      (error) => {
        this._tools.showHttpError(error);
        this.load = false;
      }
    );
  }
}
